import Pagination from "rc-pagination";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import { SelectPicker } from "rsuite";
import { getUserFilterData, getUsers, searchUsers } from "../../Auth/Api";
import Fancybox from '../../Component/FancyBox';
import Layout from '../../layout/Layout';
import { MyContext } from "../../store/Store";

const PageData = ['10', '25', '50', '100', '200'].map(
    item => ({ label: item, value: item })
);

const Users = () => {
    const history = useLocation()
    // const [current, setCurrent] = useState(1);
    // const [limit, setLimit] = useState(sessionStorage.getItem("limit") || "10");
    const [totalPage, setTotalPage] = useState(10);
    const [allUsers, setAllUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {iconcoror, Seticoncoror,filter,limit, setLimit,current, setCurrent,search, setSearch, setFilter,isOrder, setIsOrder } = useContext(MyContext);

    // const onChange = page => {
    //     sessionStorage.setItem("page", page)
    //     setCurrent(page);
    //     fetchUsers(page)
    // };
    console.log(filter)
    useEffect(() => {
        // search?.length > 0 ? handleSearch(search, current, limit)
        //     :
        //     fetchUsers(current || 1, limit)

            if (search.length > 0) {
                handleSearch(search, current, limit)
            } else if (filter) {
                const form = new FormData()
                form.append('page', current)
                form.append('limit', limit)
                form.append(filter, isOrder)
                fatchUserFilterData(form)
            } else {
                fetchUsers(current, limit)
            }
    }, [limit])
    useEffect(() => {
        sessionStorage.setItem("page", 1)
        sessionStorage.setItem("limit", 10)
    }, [])
    const fetchUsers = async (page, pageSize) => {
        setIsLoading(true)
        const response = await getUsers(page, pageSize)
        setAllUsers(response?.data?.data)
        setTotalPage(response?.data?.total_users)
        setIsLoading(false)
    }
    const onSearch = async (e) => {
        setCurrent(1)
        Seticoncoror("")
        sessionStorage.setItem("page", 1)
        setSearch(e.target.value)
        if (e.target.value.length > 0) {
            setAllUsers([]);
            handleSearch(e.target.value, current, limit)
        } else {
            fetchUsers(current, limit)
        }
    }
    const handleSearch = async (data, current, limit) => {
        setIsLoading(true)
        const response = await searchUsers(data, current, limit)
        setAllUsers(response?.data?.data)
        setTotalPage(response?.data?.total_users)
        setIsLoading(false)
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setLimit(pageSize)
        sessionStorage.setItem("page", page)
        if (search.length > 0) {
            handleSearch(search, page, pageSize)
        } else if (filter) {
            const form = new FormData()
            form.append('page', page)
            form.append('limit', pageSize)
            form.append(filter, isOrder)
            fatchUserFilterData(form)
        } else {
            fetchUsers(page, pageSize)
        }

    }
    const sorting = (col, type = "string", order, e) => {
        setCurrent(1)
        setSearch("")
        const form = new FormData()
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            setIsOrder(1)
            if (col == "Measurements_Count") {
                setFilter("measurements")
                form.append('page', 1)
                form.append('limit', limit)
                form.append('measurements', 1)
                fatchUserFilterData(form)
            }
            if (col == "Group_Count") {
                setFilter("group")
                // const form = new FormData()
                form.append('page', 1)
                form.append('limit', limit)
                form.append('group', 1)
                fatchUserFilterData(form)
            }
            if (col == "Measurements_Image_Count") {
                setFilter("measurements_image")
                // const form = new FormData()
                form.append('page', 1)
                form.append('limit', limit)
                form.append('measurements_image', 1)
                fatchUserFilterData(form)
            }
        }
        if (order === "DSC") {
            setIsOrder(-1)
            if (col == "Measurements_Count") {
                setFilter("measurements")
                // const form = new FormData()
                form.append('page', 1)
                form.append('limit', limit)
                form.append('measurements', -1)
                fatchUserFilterData(form)
            }
            if (col == "Group_Count") {
                setFilter("group")
                // const form = new FormData()
                form.append('page', 1)
                form.append('limit', limit)
                form.append('group', -1)
                fatchUserFilterData(form)
            }
            if (col == "Measurements_Image_Count") {
                setFilter("measurements_image")
                // const form = new FormData()
                form.append('page', 1)
                form.append('limit', limit)
                form.append('measurements_image', -1)
                fatchUserFilterData(form)
            }
        }
    }
    const fatchUserFilterData = async (form) => {
        setIsLoading(true)
        const response = await getUserFilterData(form)
        setAllUsers(response?.data?.data)
        setTotalPage(response?.data?.total_users)
        setIsLoading(false)

    }


    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Users <span>{(allUsers?.length > 0 && !isLoading) && ("(" + totalPage + ")")}</span></h3>
                <div className="page-heading-right">
                    <input
                        type="text"
                        name="search"
                        onChange={onSearch}
                        value={search}
                        id=""
                        placeholder="Search Email Id"
                        className="form-control wv-200 my-1 ms-3"
                    />
                    <SelectPicker
                        cleanable={false}
                        onChange={(e) => { setLimit(e); setCurrent(1); sessionStorage.setItem("limit", e); sessionStorage.setItem("page", 1); }}
                        data={PageData}
                        searchable={false}
                        defaultValue={limit}
                        className="wv-100 my-1 ms-3"
                    />
                </div>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                {isLoading && <div className="loader table-loader" ></div>}
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width="5%" className="text-center">No</th>
                                            <th width="15%">Name</th>
                                            <th width="30%">Email</th>
                                            <th width="10%"><div className='table-sort-filter justify-content-center'>Measurements Count
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "isMeasurements_count_down" ? "active" : ""}`} id="isMeasurements_count_down" onClick={(e) => { sorting('Measurements_Count', "", "DSC", e) }}></div>
                                                    <div className={`sort-up ${iconcoror === "isMeasurements_count_up" ? "active" : ""}`} id="isMeasurements_count_up" onClick={(e) => { sorting('Measurements_Count', "", "ASC", e) }}></div>
                                                </span>
                                            </div></th>
                                            <th width="10%"><div className='table-sort-filter justify-content-center'>Group Count
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "isGroup_count_down" ? "active" : ""}`} id="isGroup_count_down" onClick={(e) => { sorting('Group_Count', "", "DSC", e) }}></div>
                                                    <div className={`sort-up ${iconcoror === "isGroup_count_up" ? "active" : ""}`} id="isGroup_count_up" onClick={(e) => { sorting('Group_Count', "", "ASC", e) }}></div>
                                                </span>
                                            </div></th>
                                            <th width="10%">
                                                <div className='table-sort-filter justify-content-center'>Measurements Image Count
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "isMeasurement_image_count_down" ? "active" : ""}`} id="isMeasurement_image_count_down" onClick={(e) => { sorting('Measurements_Image_Count', "", "DSC", e) }}></div>
                                                        <div className={`sort-up ${iconcoror === "isMeasurement_image_count_up" ? "active" : ""}`} id="isMeasurement_image_count_up" onClick={(e) => { sorting('Measurements_Image_Count', "", "ASC", e) }}></div>
                                                    </span>
                                                </div></th>
                                            <th width="10%" className="text-center">Image</th>
                                            <th width="10%" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allUsers?.length > 0 ? allUsers?.map((user, index) => {
                                            return (<tr key={index}>
                                                <td className='text-center'>{(current === 1) ? index + 1 : current * limit + index + 1 - limit}</td>
                                                <td>{user?.name}</td>
                                                <td>{user?.email}</td>
                                                <td>{user?.measurement_total_count}</td>
                                                <td>{user?.group_total_count}</td>
                                                <td>{user?.total_measurement_image_count}</td>
                                                <td className='text-center'>
                                                    <Fancybox>
                                                        <a href={user?.image ? user?.image : "../../avatar/user.png"} data-fancybox="gallery">
                                                            <img src={user?.image ? user?.image : "../../avatar/user.png"} alt=""  className="hv-40 rounded-3" />
                                                        </a>
                                                    </Fancybox>
                                                </td>
                                                <td className='text-center'>
                                                    <Link to={`/Users/View/${user?.email}`}>
                                                        <Button variant="outline-warning" onClick={() => { sessionStorage.setItem("page", current); sessionStorage.setItem("limit", limit) }} size="sm" className="btn-icon"><i className='bx bx-show'></i></Button>
                                                    </Link>
                                                </td>
                                            </tr>)
                                        }) : <tr>
                                            <td colSpan="100%" className="p-0">
                                                <div className="no-found">
                                                    <img src="../../../not-found/users.svg" /><p>No Found Users</p>
                                                </div>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </Table>
                                {(allUsers?.length > 0 && totalPage > limit && !isLoading) ?
                                    <div className="pagination-custom">
                                        <Pagination
                                            className="pagination-data"
                                            onChange={PaginationChange}
                                            // defaultCurrent={current}
                                            current={current}
                                            total={totalPage}
                                            pageSize={limit}
                                            showSizeChanger={false}
                                            showTitle={false}
                                        />
                                    </div> : ""}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Users