import React, {useState, useEffect} from 'react';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import Fancybox from './../Component/FancyBox';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import NavigationMenu from '../WebComponent/NavigationMenu';
import ScrollToActive from '../WebComponent/ScrollToActive';
import Slider from 'react-slick';
import { useParallax } from "react-scroll-parallax";

const WelCome = () => {
    const currentYear = new Date().getFullYear();
    const [headerClass, setHeaderClass] = useState('scroll-off');
    const [showButton, setShowButton] = useState(false);
    const activeSection = ScrollToActive();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 10) {
                setHeaderClass('scroll-on');
            } else {
                setHeaderClass('scroll-off');
            }
        };

        const backScroll = () => {
            if (window.scrollY > 100) {
              setShowButton(true);
            } else {
              setShowButton(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', backScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', backScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    var settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 5,
        centerPadding:0,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1660,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    
    // const parallax = useParallax({
    //     translateX: [0, -30, "easeInQuint"],
    // });

    // const parallax2 = useParallax({
    //     translateY: [0, -50, "easeInQuint"],
    // });

    // const parallax3 = useParallax({
    //     translateX: [0, 30, "easeInQuint"],
    // });

    return (
        <div className={`w-gps ${headerClass}`}>
            <div className='w-header'>
                <Container>
                    <Navbar expand="lg" className="bg-body-tertiary">
                        <Navbar.Brand href="#">
                            <img src="/web/logo/logo.png" alt="GPS Area Measurements"/>
                            <span>GPS Area Measurements</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <NavigationMenu activeSection={activeSection} />
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </div>
            <section className='section w-hero' id='home'>
                <Container  className='position-relative'>
                    <Row className='align-items-center'>
                        <Col lg={6} md={12}>
                            <div className='hero-content'>
                                <h1>GPS <span>Area</span> Measurements</h1>
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className='hero-media'>
                                <img src="web/hero/hero.png" className='img-fluid app-img' alt="" />
                                <div className='hero-left'>
                                    <img src="web/hero/hero-left.svg" alt="" />
                                </div>
                                <div className='hero-right'>
                                    <img src="web/hero/hero-right.svg" alt="" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='hero-shape'>
                    <img src="web/hero/shape-1.svg" className='shape-1' alt="" />
                    <img src="web/hero/shape-2.svg" className='shape-2' alt="" />
                    <img src="web/hero/shape-3.svg" className='shape-3' alt="" />
                    <img src="web/hero/shape-4.svg" className='shape-4' alt="" />
                    <img src="web/hero/shape-5.svg" className='shape-5' alt="" />
                    <img src="web/hero/shape-6.svg" className='shape-6' alt="" />
                </div>
            </section>
            <section className='w-app-details'>
                <Container>
                    <Row>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>1M+</h3>
                                <p>Happy Users</p>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>5M+</h3>
                                <p>App Installed</p>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>30K+</h3>
                                <p>Download Per Days</p>
                            </div>
                        </Col>
                        <Col lg={3} xs={6}>
                            <div className='app-details-card'>
                                <h3>10K+</h3>
                                <p>Positive Reviews</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section w-about pb-0' id='about'>
                <Container>
                    <h2 className='title'>About Our App</h2>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className='about-media'>
                                <img src="web/about/about.png" className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='about-content'>
                                <ul>
                                    <li>Using Voice GPS Driving Directions - GPS Navigation, Find the fastest route and destinations around the world.</li>
                                    <li>The GPS route finder offers you to find the exact path between the live location and the desired destination.</li>
                                    <li>Discover the latitude, longitude, city, state, country of your live location using our current address feature.</li>
                                    <li>Speedometer helps you to measure your max speed, min speed, average speed, distance and estimated time while travelling.</li>
                                    <li>Track the status of any mobile number including service provider, service area, and network type using GPS Mobile number locator.</li>
                                    <li>Capture the image of any location using GPS camera feature that automatically fetches and shows the date, time and Address on images.</li>
                                    <li>Enhance your travelling experience using a variety of compass modes such as standard, camera, map, telescope, satellite and night mode. </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='about-shape'>
                    <img src="web/about/left.png" alt="" className='shape-1' />
                    <img src="web/about/right.png" alt="" className='shape-2' />
                </div>
            </section>
            <section className='section w-features' id='features'>
                <Container>
                    <h2 className='title'>Awesome Apps Features</h2>
                    <Row>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/1.svg" className="img-fluid" alt="Voice GPS Navigation" title="Voice GPS Navigation"/>
                                <div className="features-card-body">
                                    <h3>Voice GPS Navigation</h3>
                                    <p>Your digital copilot guides you through a voice navigation system from source to destination.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/2.svg" className="img-fluid" alt="GPS Area Calculator" title="GPS Area Calculator"/>
                                <div className="features-card-body">
                                    <h3>GPS Area Calculator</h3>
                                    <p>Set the points to measure the selected area and total distance between two locations.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/3.svg" className="img-fluid" alt="GPS Compass Direction" title="GPS Compass Direction"/>
                                <div className="features-card-body">
                                    <h3>GPS Compass Direction</h3>
                                    <p>Try various modes of GPS compass to get directions. Use the most comfortable one.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/4.svg" className="img-fluid" alt="Mobile Number Tracker" title="Mobile Number Tracker"/>
                                <div className="features-card-body">
                                    <h3>Mobile Number Tracker</h3>
                                    <p>Try various modes of GPS compass to get directions. Use the most comfortable one.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/5.svg" className="img-fluid" alt="GPS Camera" title="GPS Camera"/>
                                <div className="features-card-body">
                                    <h3>GPS Camera</h3>
                                    <p>GPS camera offers you to trace and display Date, Time and Address on photos automatically.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="features-card">
                                <img src="web/features/6.svg" className="img-fluid" alt="GPS Clock" title="GPS Clock"/>
                                <div className="features-card-body">
                                    <h3>GPS Clock</h3>
                                    <p>Stay updated with other important time of various cities and countries across the globe.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className='features-shape'>
                    <img src="web/features/left.png" alt="" className='shape-1' />
                </div>
            </section>
            <section className='section w-how-works' id='howitworks'>
                <Container>
                    <h2 className='title'>How It Works</h2>
                    <Row className='align-items-center'>
                        <Col lg={4} className='order-lg-1 order-2'>
                            <div className='work-content'>
                                <h3>Watch Free Video Demo Presentation</h3>
                                <p>Have you ever travelled with the perfect navigator to get perfect directions ? Voice GPS Driving Directions - GPS Navigation offers you the most advanced tools to make your entire journey joyful around the world. Street view, nearby places, and many more features are the perfect guides that makes your trip more adventurous.</p>
                            </div>
                        </Col>
                        <Col lg={4} className='order-lg-2 order-1'>
                            <div className='work-media'>
                                <img src="web/hero/hero.png" className="img-fluid" alt="Watch Free Video Demo Presentation" title="Watch Free Video Demo Presentation"/>
                                <Fancybox>
                                    <a href="https://www.youtube.com/embed/Sg57OVTmYm0" className='btn-play' data-fancybox="gallery">
                                        <div className="btn-outer-circle has-scale-animation"></div>
                                        <div className="btn-outer-circle has-scale-animation has-delay-short"></div>
                                        <div className="btn-icon">
                                            <i className='bx bx-play'></i>
                                        </div>
                                    </a>
                                </Fancybox>
                            </div>
                        </Col>
                        <Col lg={4} className='order-lg-3 order-3'>
                            <div className='work-list'>
                                <img src="web/how-it-work/1.svg" alt="" />
                                <div className='work-list-content'>
                                    <h4>Secure Your Data</h4>
                                    <p>High-level encryption algorithms are implemented to secure saved data into the app.</p>
                                </div>
                            </div>
                            <div className='work-list'>
                                <img src="web/how-it-work/2.svg" alt="" />
                                <div className='work-list-content'>
                                    <h4>Fast Performance</h4>
                                    <p>Easy to load each and every feature of this application in a fraction of a second.</p>
                                </div>
                            </div>
                            <div className='work-list'>
                                <img src="web/how-it-work/3.svg" alt="" />
                                <div className='work-list-content'>
                                    <h4>Free Update</h4>
                                    <p>Update the app regularly to experience the most desired, modern and useful features.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section w-screen' id='screens'>
                <Container fluid>
                    <h2 className='title'>Awesome Interface</h2>
                    <Row>
                        <Col xs={12}>
                            <Slider {...settings}>
                                <div className='text-center'>
                                    <img src="web/screen/1.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/2.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/3.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/4.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/5.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                                <div className='text-center'>
                                    <img src="web/screen/6.png" alt="Awesome Interface" title="Awesome Interface" className="img-fluid m-auto"/>
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section w-review' id='reviews'>
                <Container>
                    <h2 className='title'>What Our Customers Are Saying</h2>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <div className='review-media'>
                                <img src="/web/review/review.svg" className='img-fluid' alt="What Our Customers Are Saying" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Swiper
                                className="mySwiper"
                                navigation={true}
                                modules={[Navigation]}
                            >
                                <SwiperSlide>
                                    <div className='review-content'>
                                        <div className='review-star'>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star"></i>
                                        </div>
                                        <p>I like this GPS app. It gives me great directions to wherever I'm going. when I want to go home, I just say home and it directs me to my house. it even says welcome home when I get there. When I reach other destinations it displays the name and address on the screen. I tried many different GPS apps and so far I liked this one the best.</p>
                                        <div className='review-user'>
                                            <img src="/web/review/user1.png" alt="" />
                                            <div className="review-user-details">
                                                <h4>Roy Markert</h4>
                                                <span>January 15, 2021</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='review-content'>
                                        <div className='review-star'>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star"></i>
                                        </div>
                                        <p>At times the directions are the long way around, so when you're walking you certainly want the short routes. But over all pretty good. This app is the best, it helps me travel to different places I've never been to without the stress of parking to ask people for directions.</p>
                                        <div className='review-user'>
                                            <img src="/web/review/user2.png" alt="" />
                                            <div className="review-user-details">
                                                <h4>Deborah Johnson</h4>
                                                <span>March 10, 2020</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='review-content'>
                                        <div className='review-star'>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star fill"></i>
                                            <i className="bx bxs-star"></i>
                                        </div>
                                        <p>I only got this because Google maps' new update took away the blue route lines and had really bad suggestions along with the voice directions never working. This app helps force Google maps to do their route lines and will have the voice directions every time like it used to.</p>
                                        <div className='review-user'>
                                            <img src="/web/review/user3.png" alt="" />
                                            <div className="review-user-details">
                                                <h4>Tabby Dowling</h4>
                                                <span>April 25, 2020</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='w-download'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <div className='download-content'>
                                <h2>Download Our App Now!</h2>
                                <p>Download now to access the most exciting and useful features that ease your travelling experience with voice navigation system.</p>
                                <a className="app-store-btn btn-google" target="_blank" href="https://play.google.com/store/apps/details?id=com.voice.gps.navigation.map.location.route" role="button">
                                    <span className="app-store-btn-subtitle">Get It On</span>
                                    <span className="app-store-btn-title">Google Play</span>
                                </a>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='download-media'>
                                <img src="/web/review/download.svg" className='img-fluid' alt="Download Our App Now!" title='Download Our App Now!' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <footer className='w-footer'>
                <Container>
                    <Row>
                        <Col xs={12} className='text-center'>
                            <div className='footer-logo'>
                                <img src="/web/logo/logo.png" alt="GPS Area Measurements" title='GPS Area Measurements'/>
                                <span>GPS Area Measurements</span>
                            </div>
                            <div className='footer-menu'>
                                <ul>
                                    <li>
                                        <a href="#home">Home</a>
                                    </li>
                                    <li>
                                        <a href="#about">About</a>
                                    </li>
                                    <li>
                                        <a href="#features">Features</a>
                                    </li>
                                    <li>
                                        <a href="#howitworks">How It Works</a>
                                    </li>
                                    <li>
                                        <a href="#screens">Screens</a>
                                    </li>
                                    <li>
                                        <a href="#reviews">Reviews</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="copy-right">
                                <p>© {currentYear} GPS Area Measurements. All Rights Reserved</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
            {showButton && (
                <button id="back-to-top" onClick={scrollToTop}>
                    <i className="bx bxs-up-arrow-alt"></i>
                </button>
            )}
        </div>
    )
}

export default WelCome
