import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import Switch from 'react-switch';
import Fancybox from '../../Component/FancyBox';
import $ from 'jquery'
import { StampIsPremium, StampIsPremiumIos, StampStatus, StampStatusIos, StampsAll, StampsDelete } from "../../Auth/Api";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


const Stamps = () => {

    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);
    const [loading, Setloading] = useState(true)
    const [iconcoror, Seticoncoror] = useState()
    const [Data, SetData] = useState([])

    const GetData = async () => {
        const Result = await StampsAll()
        if (Result.data.Status === true) {
            SetData(Result.data.Data)
            Setloading(false)
            $('#remove_tr').empty()
        }
        else {
            SetData([])
            Setloading(false)
            $('#remove_tr').empty()
            $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found"><img src="../../../not-found/stamp.svg"/><p>No Found Stamps</p></div></td>')
        }
    }

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            const sorted = [...Data].sort((a, b) =>
                a[col] > b[col] ? 1 : -1
            );
            if (iconcoror !== e.target.id) {
                SetData(sorted)
            }
        }
        if (order === "DSC") {
            const sorted = [...Data].sort((a, b) =>
                a[col] < b[col] ? 1 : -1
            );
            if (iconcoror !== e.target.id) {
                SetData(sorted)
            }
        }
    }

    const DeleteStamp = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger me-2",
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel! ",
                reverseButtons: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    Setloading(true)
                    const Result = await StampsDelete(id)
                    if (Result.data.Status === true) {
                        Setloading(false)
                        toast.success("Stamps Delete Successfully...")
                        GetData()
                    }
                    else {
                        toast.error(Result.data.Response_Message)
                    }
                }
            });
    }

    const Changeispremium = async (e, id) => {
        const Result = await StampIsPremium((e === true) ? 1 : 0, id)
        if (Result.data.Status === true) {
            toast.success("Is Premium Update Successfully...")
            GetData()
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    const Changeispremiumios = async (e, id) => {
        const Result = await StampIsPremiumIos((e === true) ? 1 : 0, id)
        if (Result.data.Status === true) {
            toast.success("Is Premium Ios Update Successfully...")
            GetData()
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    const Changestatus = async (e, id) => {
        const Result = await StampStatus((e === true) ? 1 : 0, id)
        if (Result.data.Status === true) {
            toast.success("Status Update Successfully...")
            GetData()
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    const Changestatusios = async (e, id) => {
        const Result = await StampStatusIos((e === true) ? 1 : 0, id)
        if (Result.data.Status === true) {
            toast.success("Status Ios Update Successfully...")
            GetData()
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    const getData = (current, pageSize) => {
        return Data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(Data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    useEffect(() => {
        GetData()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Stamps</h3>
                <div className="page-heading-right">
                    <Link to="/Stamps/Add" className="my-1 ms-3">
                        <Button variant="primary" value="create">Add New</Button>
                    </Link>
                </div>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width="6%" className="text-center">No</th>
                                            <th width="12%" className="text-center">Main Image</th>
                                            <th width="12%" className="text-center">Front Image</th>
                                            <th width="10%" className="text-center">Zip Name</th>
                                            <th width="10%" className="text-center">Zip Name iOS</th>
                                            <th width="10%" className="text-center">
                                                <div className='table-sort-filter justify-content-center'>Is Premium
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "ispremium_down" ? "active" : ""}`} id="ispremium_down" onClick={(e) => { sorting('is_premium', "", "DSC", e) }}></div>
                                                        <div className={`sort-up ${iconcoror === "ispremium_up" ? "active" : ""}`} id="ispremium_up" onClick={(e) => { sorting('is_premium', "", "ASC", e) }}></div>
                                                    </span>
                                                </div>
                                            </th>
                                            <th width="10%" className="text-center">
                                                <div className='table-sort-filter justify-content-center'>Is Premium iOS
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "ispremiumios_down" ? "active" : ""}`} id="ispremiumios_down" onClick={(e) => { sorting('is_premium_ios', "", "DSC", e) }}></div>
                                                        <div className={`sort-up ${iconcoror === "ispremiumios_up" ? "active" : ""}`} id="ispremiumios_up" onClick={(e) => { sorting('is_premium_ios', "", "ASC", e) }}></div>
                                                    </span>
                                                </div>
                                            </th>
                                            <th width="10%" className="text-center">
                                                <div className='table-sort-filter justify-content-center'>Status
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "", "DSC", e) }}></div>
                                                        <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "", "ASC", e) }}></div>
                                                    </span>
                                                </div>
                                            </th>
                                            <th width="10%" className="text-center">
                                                <div className='table-sort-filter justify-content-center'>Status iOS
                                                    <span className='table-sort'>
                                                        <div className={`sort-down ${iconcoror === "statusios_down" ? "active" : ""}`} id="statusios_down" onClick={(e) => { sorting('status_ios', "", "DSC", e) }}></div>
                                                        <div className={`sort-up ${iconcoror === "statusios_up" ? "active" : ""}`} id="statusios_up" onClick={(e) => { sorting('status_ios', "", "ASC", e) }}></div>
                                                    </span>
                                                </div>
                                            </th>
                                            <th width="10%" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getData(current, size).map((val, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                                        <td className='text-center'>
                                                            <Fancybox>
                                                                <a href={val.main_image} data-fancybox="gallery">
                                                                    <img src={val.main_image} alt="" className="hv-40 rounded-3" />
                                                                </a>
                                                            </Fancybox>
                                                        </td>
                                                        <td className='text-center'>
                                                            <Fancybox>
                                                                <a href={val.front_image} data-fancybox="gallery">
                                                                    <img src={val.front_image} alt="" className="hv-40 rounded-3" />
                                                                </a>
                                                            </Fancybox>
                                                        </td>
                                                        <td className='text-center'>
                                                            <a href={val.zip_name}>
                                                                <Button variant="outline-info" size="sm" className="btn-icon"><i className='bx bx-download' ></i></Button>
                                                            </a>
                                                        </td>
                                                        <td className='text-center'>
                                                            <a href={val.zip_name_ios}>
                                                                <Button variant="outline-info" size="sm" className="btn-icon"><i className='bx bx-download' ></i></Button>
                                                            </a>
                                                        </td>
                                                        <td className='text-center'>
                                                            <Switch
                                                                onChange={(e) => { Changeispremium(e, val._id) }}
                                                                checked={(val.is_premium === 1) ? true : false}
                                                                offColor="#C8C8C8"
                                                                onColor="#0093ed"
                                                                height={30}
                                                                width={70}
                                                                className="react-switch"
                                                                uncheckedIcon={
                                                                    <div className="react-switch-off">NO</div>
                                                                }
                                                                checkedIcon={
                                                                    <div className="react-switch-on">YES</div>
                                                                }
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Switch
                                                                onChange={(e) => { Changeispremiumios(e, val._id) }}
                                                                checked={(val.is_premium_ios === 1) ? true : false}
                                                                offColor="#C8C8C8"
                                                                onColor="#0093ed"
                                                                height={30}
                                                                width={70}
                                                                className="react-switch"
                                                                uncheckedIcon={
                                                                    <div className="react-switch-off">NO</div>
                                                                }
                                                                checkedIcon={
                                                                    <div className="react-switch-on">YES</div>
                                                                }
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Switch
                                                                onChange={(e) => { Changestatus(e, val._id) }}
                                                                checked={(val.status === 1) ? true : false}
                                                                offColor="#C8C8C8"
                                                                onColor="#0093ed"
                                                                height={30}
                                                                width={70}
                                                                className="react-switch"
                                                                uncheckedIcon={
                                                                    <div className="react-switch-off">OFF</div>
                                                                }
                                                                checkedIcon={
                                                                    <div className="react-switch-on">ON</div>
                                                                }
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Switch
                                                                onChange={(e) => { Changestatusios(e, val._id) }}
                                                                checked={(val.status_ios === 1) ? true : false}
                                                                offColor="#C8C8C8"
                                                                onColor="#0093ed"
                                                                height={30}
                                                                width={70}
                                                                className="react-switch"
                                                                uncheckedIcon={
                                                                    <div className="react-switch-off">OFF</div>
                                                                }
                                                                checkedIcon={
                                                                    <div className="react-switch-on">ON</div>
                                                                }
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to={`/Stamps/View/${val._id}`}>
                                                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                            </Link>
                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => DeleteStamp(val._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr id="remove_tr"></tr>
                                    </tbody>
                                </Table>
                                {(Data.length > size) ?
                                    <div className="pagination-custom">
                                        <Pagination
                                            className="pagination-data"
                                            onChange={PaginationChange}
                                            total={Data.length}
                                            current={current}
                                            pageSize={size}
                                            showSizeChanger={false}
                                            itemRender={PrevNextArrow}
                                            onShowSizeChange={PerPageChange}
                                            showTitle={false}
                                        />
                                    </div> : ""}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Stamps