import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import 'rsuite/dist/rsuite.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './bootstrap.css';
import 'boxicons/css/boxicons.css';
import '../node_modules/swiper/swiper-bundle.min.css'
import './App.css';
import './utilities.css';
import 'react-toastify/dist/ReactToastify.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from './pages/Home';
import Login from './pages/Login';

import Stamps from './pages/Stamps/Stamps';
import StampsAdd from './pages/Stamps/StampsAdd';
import StampsEdit from './pages/Stamps/StampsEdit';
import StampsView from './pages/Stamps/StampsView';

import News from './pages/News/News';
import NewsAdd from './pages/News/NewsAdd';
import NewsEdit from './pages/News/NewsEdit';
import NewsView from './pages/News/NewsView';

import Users from './pages/Users/Users';
import UsersView from './pages/Users/UsersView';

import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import Error from './pages/Error/Error';
import NewWebView from './pages/NewWebview'
import WellCome from './pages/WelCome';

import KML from './pages/KML';
import KMLiOS from './pages/KMLiOS';
import MapWebView from './pages/MapWebView';

function App() {
    axios.defaults.baseURL = process.env.REACT_APP_API_LINK
    const ProtectedRoute = ({ redirectPath = "/" }) => {
        if (!Cookies.get('jwt-VoiceGPS')) {
            return <Navigate to={redirectPath} replace />
        }
        else {
            axios.defaults.headers.Authorization = `Bearer ${Cookies.get('jwt-VoiceGPS')}`
            return <Outlet />;
        }
    }

    const PrivateRoute = ({ redirectPath = "/Home" }) => {
        if (!Cookies.get('jwt-VoiceGPS')) {
            return <Outlet />

        }
        else {
            axios.defaults.headers.Authorization = `Bearer ${Cookies.get('jwt-VoiceGPS')}`
            return <Navigate to={redirectPath} replace />;
        }
    }

    return (
        <>
            <ToastContainer position='bottom-right' autoClose="500" closeOnClick="true" />
            <BrowserRouter>
                <Routes>
                    <Route element={<ProtectedRoute />}>

                        <Route path="/Home" element={<Home />} />

                        <Route path="/Stamps" element={<Stamps />} />
                        <Route path="/Stamps/Add" element={<StampsAdd />} />
                        <Route path="/Stamps/Edit/:id" element={<StampsEdit />} />
                        <Route path="/Stamps/View/:id" element={<StampsView />} />

                        <Route path="/News" element={<News />} />
                        <Route path="/News/Add" element={<NewsAdd />} />
                        <Route path="/News/Edit/:id" element={<NewsEdit />} />
                        <Route path="/News/View/:id" element={<NewsView />} />

                        <Route path='/Users' element={<Users/>} />
                        <Route path='/Users/View' element={<UsersView/>} />
                        <Route path='/Users/View/:id' element={<UsersView/>} />

                    </Route>
                    <Route path='/destination/:title' element={<NewWebView />} />

                    <Route path='*' element={<Error />} />

                    <Route path="/" element={<WellCome />} />
                    <Route path='/kml_upload' element={<KML />} />
                    <Route path='/ios_kml' element={<KMLiOS />} />
                    <Route path='/mapwebview' element={<MapWebView />} />
                    <Route path='/mapwebview/:id' element={<MapWebView />} />

                    <Route element={<PrivateRoute />}>
                        <Route path="/login" element={<Login />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App;