import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch';
import Fancybox from '../../Component/FancyBox';
import { toast } from "react-toastify";
import { StampEdit, StampView } from "../../Auth/Api";
import { useEffect } from "react";

const StampsEdit = () => {

    const Redirect = useNavigate()
    const { id } = useParams()
    const [loading, Setloading] = useState(false)
    const [validate, setValidate] = useState(false)
    const [Data, SetData] = useState({
        main_image: "",
        front_image: "",
        zip_name: "",
        zip_name_ios: "",
        is_premium: 0,
        is_premium_ios: 0,
        status: 0,
        status_ios: 0,
    })

    const InputFile = (e) => {
        SetData({ ...Data, [e.target.name]: e.target.files[0] })
    }

    const GetData = async () => {
        const Result = await StampView(id)
        if (Result.data.Status === true) {
            SetData(Result.data.Data)
        }
    }

    const Save = async () => {
        setValidate(true)
        Setloading(true)
        const Result = await StampEdit(Data, id)
        if (Result.data.Status === true) {
            toast.success("Stamps Update Successfully...")
            Setloading(false)
            Redirect(`/Stamps/view/${id}`)
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    useEffect(() => {
        GetData()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit Stamps</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/Home"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/Stamps">Stamps</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Stamps</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card className="mb-4">
                            <Card.Body>
                            {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                                <Row>
                                    <Col md={6}>
                                        <Form.Label htmlFor="mainimage">Main Image</Form.Label>
                                        <InputGroup className="my-2">
                                            <Form.Control type="file" name="main_image" onChange={(e) => { InputFile(e) }} />
                                            <Fancybox>
                                                <a href={Data.main_image} data-fancybox="gallery">
                                                    <img src={Data.main_image} alt="" className="hv-40 rounded-3" />
                                                </a>
                                            </Fancybox>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="mainimage">Front Image</Form.Label>
                                        <InputGroup className="my-2">
                                            <Form.Control type="file" name="front_image" onChange={(e) => { InputFile(e) }} />
                                            <Fancybox>
                                                <a href={Data.front_image} data-fancybox="gallery">
                                                    <img src={Data.front_image} alt="" className="hv-40 rounded-3" />
                                                </a>
                                            </Fancybox>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="mainimage">ZIP Name</Form.Label>
                                        <InputGroup className="my-2">
                                            <Form.Control type="file" name="zip_name" onChange={(e) => { InputFile(e) }} />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="mainimage">ZIP Name iOS</Form.Label>
                                        <InputGroup className="my-2">
                                            <Form.Control type="file" name="zip_name_ios" onChange={(e) => { InputFile(e) }} />
                                        </InputGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label htmlFor="ispremium" className="d-block mb-2">Is Premium</Form.Label>
                                        <Switch
                                            onChange={(e) => { SetData({ ...Data, is_premium: (e === true) ? 1 : 0 }) }}
                                            checked={(Data.is_premium === 1) ? true : false}
                                            offColor="#C8C8C8"
                                            onColor="#0093ed"
                                            height={30}
                                            width={70}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div className="react-switch-off">NO</div>
                                            }
                                            checkedIcon={
                                                <div className="react-switch-on">YES</div>
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label htmlFor="ispremiumios" className="d-block mb-2">Is Premium iOS</Form.Label>
                                        <Switch
                                            onChange={(e) => { SetData({ ...Data, is_premium_ios: (e === true) ? 1 : 0 }) }}
                                            checked={(Data.is_premium_ios === 1) ? true : false}
                                            offColor="#C8C8C8"
                                            onColor="#0093ed"
                                            height={30}
                                            width={70}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div className="react-switch-off">NO</div>
                                            }
                                            checkedIcon={
                                                <div className="react-switch-on">YES</div>
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label htmlFor="status" className="d-block mb-2">Status</Form.Label>
                                        <Switch
                                            onChange={(e) => { SetData({ ...Data, status: (e === true) ? 1 : 0 }) }}
                                            checked={(Data.status === 1) ? true : false}
                                            offColor="#C8C8C8"
                                            onColor="#0093ed"
                                            height={30}
                                            width={70}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div className="react-switch-off">OFF</div>
                                            }
                                            checkedIcon={
                                                <div className="react-switch-on">ON</div>
                                            }
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label htmlFor="statusios" className="d-block mb-2">Status iOS</Form.Label>
                                        <Switch
                                            onChange={(e) => { SetData({ ...Data, status_ios: (e === true) ? 1 : 0 }) }}
                                            checked={(Data.status_ios === 1) ? true : false}
                                            offColor="#C8C8C8"
                                            onColor="#0093ed"
                                            height={30}
                                            width={70}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div className="react-switch-off">OFF</div>
                                            }
                                            checkedIcon={
                                                <div className="react-switch-on">ON</div>
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={Save}>Save</Button>
                                <Link to={`/Stamps/view/${id}`}>
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default StampsEdit