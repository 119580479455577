import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { toast } from "react-toastify";
import { StampAdd } from "../../Auth/Api";

const StampsAdd = () => {

    const Redirect = useNavigate()
    const [loading, Setloading] = useState(false)
    const [validate, setValidate] = useState(false)
    const [Data, SetData] = useState({
        main_image: "",
        front_image: "",
        zip_name: "",
        zip_name_ios: "",
        is_premium: 0,
        is_premium_ios: 0,
        status: 0,
        status_ios: 0,
    })

    const InputFile = (e) => {
        SetData({ ...Data, [e.target.name]: e.target.files[0] })
    }

    const Save = async () => {
        setValidate(true)
        Setloading(true)
        const Result = await StampAdd(Data)
        if (Result.data.Status === true) {
            toast.success("Stamps Saved Successfully...")
            Setloading(false)
            Redirect("/Stamps")
        }
        else {
            toast.error(Result.data.Response_Message)
        }
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Create Stamps</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/Home"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/Stamps">Stamps</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create Stamps</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form method='post' noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label htmlFor="mainimage">Main Image</Form.Label>
                                            <Form.Control type="file" name="main_image" className="my-2" onChange={(e) => { InputFile(e) }} />
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label htmlFor="mainimage">Front Image</Form.Label>
                                            <Form.Control type="file" name="front_image" className="my-2" onChange={(e) => { InputFile(e) }} />
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label htmlFor="mainimage">ZIP Name</Form.Label>
                                            <Form.Control type="file" name="zip_name" className="my-2" onChange={(e) => { InputFile(e) }} />
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label htmlFor="mainimage">ZIP Name iOS</Form.Label>
                                            <Form.Control type="file" name="zip_name_ios" className="my-2" onChange={(e) => { InputFile(e) }} />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label htmlFor="ispremium" className="d-block mb-2">Is Premium</Form.Label>
                                            <Switch
                                                onChange={(e) => { SetData({ ...Data, is_premium: (e === true) ? 1 : 0 }) }}
                                                checked={(Data.is_premium === 1) ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">NO</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">YES</div>
                                                }
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label htmlFor="ispremiumios" className="d-block mb-2">Is Premium iOS</Form.Label>
                                            <Switch
                                                onChange={(e) => { SetData({ ...Data, is_premium_ios: (e === true) ? 1 : 0 }) }}
                                                checked={(Data.is_premium_ios === 1) ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">NO</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">YES</div>
                                                }
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label htmlFor="status" className="d-block mb-2">Status</Form.Label>
                                            <Switch
                                                onChange={(e) => { SetData({ ...Data, status: (e === true) ? 1 : 0 }) }}
                                                checked={(Data.status === 1) ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">ON</div>
                                                }
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label htmlFor="statusios" className="d-block mb-2">Status iOS</Form.Label>
                                            <Switch
                                                onChange={(e) => { SetData({ ...Data, status_ios: (e === true) ? 1 : 0 }) }}
                                                checked={(Data.status_ios === 1) ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">ON</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={Save} >Save</Button>
                                    <Link to='/Stamps'>
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default StampsAdd