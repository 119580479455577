import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch';
import { useEffect } from "react";
import { NewUpdate, NewView } from "../../Auth/Api";
import Fancybox from "../../Component/FancyBox";
import { toast } from "react-toastify";

const NewsEdit = () => {

    const Redirect = useNavigate()
    const [loading, Setloading] = useState(false)
    const [validate, setValidate] = useState(false)
    const [Images, SetImages] = useState([])
    const [AddImages, SetAddImages] = useState([])
    const [DeleteImages, SetDeleteImages] = useState([])
    const { id } = useParams()
    const [Data, SetData] = useState({
        title: "",
        header: "",
        short_description: "",
        description: "",
        latitude: "",
        longitude: "",
        location_name: "",
        is_active: 0
    })

    const GetEditData = async () => {
        const Result = await NewView(id)
        if (Result.data.Status === true) {
            SetData(Result?.data?.Data?.News)
            SetImages(Result?.data?.Data?.Newsimages)
            Setloading(false)
        }
        else {
            SetData([])
            Setloading(false)
        }
    }

    const InputData = async (e) => {
        SetData({ ...Data, [e.target.name]: e.target.value })
    }

    const InputFiles = async (e) => {
        var file = []
        Object.values(e.target.files).map((val, index) => {
            file.push(val)
        })
        SetAddImages(file)
    }

    const RemoveSelectImage = async (index) => {
        var imgarr = [...AddImages]
        imgarr.splice(index, 1)
        SetAddImages(imgarr)
    }

    const RemoveImage = async (index, id) => {
        var deletearr = [...DeleteImages]
        var imagesarr = [...Images]
        imagesarr.splice(index, 1)
        SetImages(imagesarr)
        deletearr.push(id)
        SetDeleteImages(deletearr)
    }

    const save = async () => {
        if (Data.title === "" || Data.header === "" || Data.latitude === "" || Data.longitude === "" || Data.location_name === "") {
            setValidate(true)
        }
        else {
            Setloading(true)
            const Result = await NewUpdate(id, Data, AddImages, DeleteImages)
            console.log(Result);
            if (Result.data.Status === true) {
                toast.success("News Update Successfully...")
                Setloading(false)
                Redirect(`/News/View/${id}`)
            }
            else {
                toast.error(Result.data.Response_Message)
            }
        }
    }

    useEffect(() => {
        GetEditData()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit News</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/News">News</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit News</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card className="mb-4">
                            <Card.Body>
                            {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                                <Row>
                                    <Col md={6}>
                                        <Form.Label htmlFor="title">Title</Form.Label>
                                        <Form.Control type="text" className="my-2" name="title" value={Data.title} onChange={(e) => { InputData(e) }} required />
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label htmlFor="header">Header</Form.Label>
                                        <Form.Control type="text" className="my-2" name="header" value={Data.header} onChange={(e) => { InputData(e) }} required />
                                    </Col>
                                    <Col md={12}>
                                        <Form.Label htmlFor="shortdescription">Short Description</Form.Label>
                                        <Form.Control as="textarea" rows={2} className="my-2" name="short_description" value={Data.short_description} onChange={(e) => { InputData(e) }} />
                                    </Col>
                                    <Col md={12}>
                                        <Form.Label htmlFor="description">Description</Form.Label>
                                        <Form.Control as="textarea" rows={5} className="my-2" name="description" value={Data.description} onChange={(e) => { InputData(e) }} />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="latitude">Latitude</Form.Label>
                                        <Form.Control type="text" className="my-2" name="latitude" value={Data.latitude} onChange={(e) => { InputData(e) }} required />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="longitude">Longitude</Form.Label>
                                        <Form.Control type="text" className="my-2" name="longitude" value={Data.longitude} onChange={(e) => { InputData(e) }} required />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="locationname">Location Name</Form.Label>
                                        <Form.Control type="text" className="my-2" name="location_name" value={Data.location_name} onChange={(e) => { InputData(e) }} required />
                                    </Col>
                                    <Col md={8}>
                                        <Form.Label htmlFor="image">Image</Form.Label>
                                        <Form.Control type="file" className="my-2" onChange={(e) => { InputFiles(e) }} multiple />
                                        {
                                            Images?.map((val, index) => {
                                                return (
                                                    <div className="multi-img my-2">
                                                        <Fancybox>
                                                            <a href={val.image} data-fancybox="gallery" alt="">
                                                                <img src={val.image} alt="" width="100" height="100" />
                                                            </a>
                                                        </Fancybox>
                                                        <i className="bx bx-x" onClick={() => { RemoveImage(index, val._id) }}></i>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            AddImages?.map((val, index) => {
                                                return (
                                                    <div className="multi-img my-2">
                                                        <Fancybox>
                                                            <a href={URL.createObjectURL(val)} data-fancybox="gallery" alt="">
                                                                <img src={URL.createObjectURL(val)} alt="" width="100" height="100" />
                                                            </a>
                                                        </Fancybox>
                                                        <i className="bx bx-x" onClick={() => { RemoveSelectImage(index) }}></i>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label htmlFor="status" className="d-block mb-2">Status</Form.Label>
                                        <Switch
                                            onChange={(e) => { SetData({ ...Data, is_active: (e === true) ? 1 : 0 }) }}
                                            checked={(Data.is_active === 1) ? true : false}
                                            offColor="#C8C8C8"
                                            onColor="#0093ed"
                                            height={30}
                                            width={70}
                                            className="react-switch"
                                            uncheckedIcon={
                                                <div className="react-switch-off">OFF</div>
                                            }
                                            checkedIcon={
                                                <div className="react-switch-on">ON</div>
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={save} >Save</Button>
                                <Link to={`/News/View/${id}`}>
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default NewsEdit