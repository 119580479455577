import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import Fancybox from '../../Component/FancyBox';
import { useState } from 'react';
import { useEffect } from 'react';
import { NewView, NewsAll, NewsDelete } from '../../Auth/Api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import copy from 'copy-to-clipboard';

const NewsView = () => {

    const Redirect = useNavigate()
    const [Data, SetData] = useState([])
    const [loading, Setloading] = useState(true)
    const [ViewSearchData, SetViewSearchData] = useState([])
    const { id } = useParams()
    const [Id, SetId] = useState(id)

    const GetAllData = async () => {
        const Result = await NewsAll()
        if (Result.data.Status === true) {
            var SetSearchData = []
            Result.data.Data.map((val) => {
                SetSearchData.push({
                    label: val.title,
                    value: val._id
                })
                SetViewSearchData(SetSearchData)
            })
        }
        else {
            SetSearchData = []
            SetViewSearchData(SetSearchData)
        }
    }

    const GetViewData = async (id) => {
        const Result = await NewView((id === "") ? Id : id)
        if (Result.data.Status === true) {
            SetData(Result.data.Data)
            Setloading(false)
        }
        else {
            SetData([])
            Setloading(false)
        }
    }

    const DeleteNews = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger me-2",
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel! ",
                reverseButtons: true,
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    Setloading(true)
                    const Result = await NewsDelete(id)
                    if (Result.data.Status === true) {
                        Setloading(false)
                        toast.success("News Delete Successfully...")
                        Redirect("/News")
                    }
                    else {
                        toast.error(Result.data.Response_Message)
                    }
                }
            });
    }

    const CopyNewUrl = async (newsurl) => {
        copy(newsurl)
        toast.success(`NewsUrl Copy Successfully`);
    }

    const InputData = (e) => {
        SetId(e)
        GetViewData(e)
    }

    useEffect(() => {
        GetViewData("")
        GetAllData()
    }, [])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3><Link to="/News" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View News - <span>{`${Data?.News?.title}`}</span></h3>
                <div className="page-heading-right">
                    <SelectPicker data={ViewSearchData} defaultValue={Id} cleanable={false} className="wv-200 my-1 ms-3" placeholder="Select Title" onChange={e => InputData(e)} placement="bottomEnd" />
                    <Link to={`/News/Edit/${Id}`}>
                        <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
                    </Link>
                    <Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={() => DeleteNews(id)}><i className="bx bx-trash-alt"></i></Button>
                </div>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                        <Row>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Title</p><span>{Data?.News?.title}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Header</p><span>{Data?.News?.header}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Location Name</p><span>{Data?.News?.location_name}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Latitude</p><span>{Data?.News?.latitude}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Longitude</p><span>{Data?.News?.longitude}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p><span>{(Data?.News?.is_active === 1) ? "NO" : "OFF"}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Image</p>
                                    <span>
                                        {
                                            Data?.Newsimages?.map((val, index) => {
                                                return (
                                                    <Fancybox key={index}>
                                                        <a href={val?.image} data-fancybox="gallery">
                                                            <img src={val?.image} alt="" className="hv-50 wv-100 rounded-3 me-2" />
                                                        </a>
                                                    </Fancybox>
                                                )
                                            })
                                        }
                                    </span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Webview</p><span>
                                        <Button variant="outline-success" size="sm" className="me-2 btn-icon" onClick={() => { CopyNewUrl(Data?.News?.news_url) }} ><i className='bx bx-copy'></i></Button>
                                        <a href={Data?.News?.news_url} target="_blank" rel="noreferrer" >
                                            <Button variant="outline-info" size="sm" className="btn-icon"><i className='bx bx-link-external'></i></Button>
                                        </a>
                                    </span>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Short Description</p>
                                    <span>
                                        {Data?.News?.short_description}
                                    </span>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Description</p>
                                    <span dangerouslySetInnerHTML={{ __html: Data?.News?.description }}></span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default NewsView