import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch';
import Fancybox from "../../Component/FancyBox";
import { NewAdd } from "../../Auth/Api";
import { toast } from "react-toastify";

const NewsAdd = () => {

    const Redirect = useNavigate()
    const [loading, Setloading] = useState(false)
    const [validate, setValidate] = useState(false)
    const [Images, SetImages] = useState([])
    const [Data, SetData] = useState({
        title: "",
        header: "",
        short_description: "",
        description: "",
        latitude: "",
        longitude: "",
        location_name: "",
        is_active: 0
    })

    const InputData = async (e) => {
        SetData({ ...Data, [e.target.name]: e.target.value })
    }

    const InputFiles = async (e) => {
        var file = []
        Object.values(e.target.files).map((val, index) => {
            file.push(val)
        })
        SetImages(file)
    }

    const Deleteimage = async (index) => {
        var imagesarr = [...Images]
        imagesarr.splice(index, 1)
        SetImages(imagesarr)
    }

    const Save = async () => {
        if (Data.title === "" || Data.header === "" || Data.latitude === "" || Data.longitude === "" || Data.location_name === "") {
            setValidate(true)
        }
        else {
            Setloading(true)
            const Result = await NewAdd(Data, Images)
            if (Result.data.Status === true) {
                toast.success("News Saved Successfully...")
                Setloading(false)
                Redirect("/News")
            }
            else {
                toast.error(Result.data.Response_Message)
            }
        }
    }



    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Create News</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/News">News</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create News</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form method='post' noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    {(loading === true) ? <div className="loader table-loader" ></div> : <></>}
                                    <Row>
                                        <Col md={6}>
                                            <Form.Label htmlFor="title">Title</Form.Label>
                                            <Form.Control type="text" name="title" className="my-2" onChange={(e) => { InputData(e) }} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Your Title
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label htmlFor="header">Header</Form.Label>
                                            <Form.Control type="text" name="header" className="my-2" onChange={(e) => { InputData(e) }} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Your Header
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Label htmlFor="shortdescription">Short Description</Form.Label>
                                            <Form.Control as="textarea" name="short_description" rows={2} className="my-2" onChange={(e) => { InputData(e) }} />
                                        </Col>
                                        <Col md={12}>
                                            <Form.Label htmlFor="description">Description</Form.Label>
                                            <Form.Control as="textarea" name="description" rows={5} className="my-2" onChange={(e) => { InputData(e) }} />
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="latitude">Latitude</Form.Label>
                                            <Form.Control type="text" name="latitude" className="my-2" onChange={(e) => { InputData(e) }} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Your Latitude
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="longitude">Longitude</Form.Label>
                                            <Form.Control type="text" name="longitude" className="my-2" onChange={(e) => { InputData(e) }} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Your Longitude
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="locationname">Location Name</Form.Label>
                                            <Form.Control type="text" name="location_name" className="my-2" onChange={(e) => { InputData(e) }} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Your Location Name
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Label htmlFor="image">Image</Form.Label>
                                            <Form.Control type="file" onChange={(e) => { InputFiles(e) }} className="my-2" multiple />
                                            {
                                                Images?.map((val, index) => {
                                                    return (
                                                        <div className="multi-img my-2">
                                                            <Fancybox>
                                                                <a href={URL.createObjectURL(val)} data-fancybox="gallery" alt="">
                                                                    <img src={URL.createObjectURL(val)} alt="" width="100" height="100" />
                                                                </a>
                                                            </Fancybox>
                                                            <i className="bx bx-x" onClick={() => { Deleteimage(index) }} ></i>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="status" className="d-block mb-2">Status</Form.Label>
                                            <Switch
                                                onChange={(e) => { SetData({ ...Data, is_active: (e === true) ? 1 : 0 }) }}
                                                checked={(Data.is_active === 1) ? true : false}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">ON</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={Save} >Save</Button>
                                    <Link to='/News'>
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default NewsAdd