import React from "react";
import '../bootstrap.css';
import '../App.css';
import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import { kml_upload } from "../Auth/Api";
import { GoogleMap, LoadScript, KmlLayer } from '@react-google-maps/api';
import { useState } from "react";
import Error from "./Error/Error";


const KML = () => {
    var kmlParam = new URLSearchParams(window.location.search)
    var kmlValue = kmlParam.get('kml')
    const [Kml, Gettkml] = useState([])

    const GetKml = async () => {
        const Result = await kml_upload(kmlValue)
        if (Result.data.Status === true) {
            Gettkml(Result.data.Data)
        } else {
            <Error />
        }
    }

    useEffect(() => {
        GetKml()
    }, [])

    return (
        <>
            <div className="web-header">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h1 className="logo">GPS Area Measurement</h1>
                        </Col>
                        <Col md={6}>
                            <div className="app-buttons">
                                <a className="app-store-btn btn-google ms-2 me-2" target="_blank" href="https://play.google.com/store/apps/details?id=com.voice.gps.navigation.map.location.route" role="button">
                                    <span className="app-store-btn-subtitle">Get It On</span>
                                    <span className="app-store-btn-title">Google Play</span>
                                </a>
                                <a className="app-store-btn btn-apple ms-2 me-2" target="_blank" href="https://apps.apple.com/us/app/gps-area-measurements/id1238984272" role="button">
                                    <span className="app-store-btn-subtitle">Available on the</span>
                                    <span className="app-store-btn-title">App Store</span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="map">
                <LoadScript googleMapsApiKey="AIzaSyAygL9d4ktwAFZyY9VpID0oX_3zajsPw74">
                    <GoogleMap
                        center={{ lat: 41.876, lng: -87.624 }}
                        zoom={10}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                    >
                        <KmlLayer url={Kml} />
                    </GoogleMap>
                </LoadScript>
            </div>
        </>
    )
}

export default KML