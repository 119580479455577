import React, { useState } from 'react';
import { createContext } from 'react';

export const MyContext = createContext();

function MyContextProvider({ children }) {
  const [iconcoror, Seticoncoror] = useState("")
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState( "10");
  const [isOrder, setIsOrder] = useState(); 
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState([]);

  return (
    <MyContext.Provider value={{ iconcoror, Seticoncoror,current,search, setSearch, setCurrent,limit, setLimit,filter, setFilter,isOrder, setIsOrder}}>
      {children}
    </MyContext.Provider>
  );
}

export default MyContextProvider;