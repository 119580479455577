import React from 'react';
import { Nav } from 'react-bootstrap';

function NavigationMenu({ activeSection }) {
  return (
    <>
    <Nav className="ms-auto align-items-center" navbarScroll>
        <Nav.Link className={activeSection === 'home' ? 'active' : ''} href="#home">Home</Nav.Link>
        <Nav.Link className={activeSection === 'about' ? 'active' : ''} href="#about">About</Nav.Link>
        <Nav.Link className={activeSection === 'features' ? 'active' : ''} href="#features">Features</Nav.Link>
        <Nav.Link className={activeSection === 'howitworks' ? 'active' : ''} href="#howitworks">How It Works</Nav.Link>
        <Nav.Link className={activeSection === 'screens' ? 'active' : ''} href="#screens">Screens</Nav.Link>
        <Nav.Link className={activeSection === 'reviews' ? 'active' : ''} href="#reviews">Reviews</Nav.Link>
        <a className="app-store-btn btn-google ms-lg-3" target="_blank" href="https://play.google.com/store/apps/details?id=com.voice.gps.navigation.map.location.route" role="button">
            <span className="app-store-btn-subtitle">Get It On</span>
            <span className="app-store-btn-title">Google Play</span>
        </a>
    </Nav>
    </>
  );
}

export default NavigationMenu;
