import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import '../App.css';
import '../bootstrap.css';
import { useParams } from 'react-router-dom';


const MapWebView = () => {
    const params =useParams();
    const abc=JSON.parse(params.id)
    return (
        <>
            <div className="web-header">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <h1 className="logo">GPS Area Measurement</h1>
                        </Col>
                        <Col md={6}>
                            <div className="app-buttons">
                                <a className="app-store-btn btn-google ms-2 me-2" target="_blank" href="https://play.google.com/store/apps/details?id=com.voice.gps.navigation.map.location.route" role="button">
                                    <span className="app-store-btn-subtitle">Get It On</span>
                                    <span className="app-store-btn-title">Google Play</span>
                                </a>
                                <a className="app-store-btn btn-apple ms-2 me-2" target="_blank" href="https://apps.apple.com/us/app/gps-area-measurements/id1238984272" role="button">
                                    <span className="app-store-btn-subtitle">Available on the</span>
                                    <span className="app-store-btn-title">App Store</span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="map">
                <LoadScript googleMapsApiKey="AIzaSyB2dv1u9cWpDgtl_wn2TYXlQFdAspuDSlM">
                    <GoogleMap
                        // mapContainerClassName="App-map"
                        center={abc[0]}
                        zoom={17}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                    >
                        <Polygon path={abc} />
                    </GoogleMap>
                </LoadScript>
            </div>
        </>
    )
}

export default MapWebView